<template>
  <div class="d-flex justify-content-between room-information mt-3 mb-3">
    <div class="form-group">
      <label>{{ $t('booking.num-tickets') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="0"
        readonly
        :value="numOfTickets"
      />
    </div>
    <div class="form-group icon" v-if="ticketName">
      <label>{{ $t('booking.name-ticket') }}</label
      >
      <input
        type="text"
        class="form-control"
        placeholder=""
        readonly
        v-model="ticketName"
      />
    </div>
    <div class="form-group icon" v-if="ticketSupplier">
      <label>{{ $t('booking.supplier-ticket') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder=""
        readonly
        v-model="ticketSupplier"
      />
    </div>
    <div class="form-group icon">
      <label>{{ $t('booking.departure-date') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="01-01-2021"
        readonly
        v-model="fromDate"
      />
      <div class="icon-form"><i class="far fa-calendar-alt"></i></div>
    </div>
    <div class="form-group icon">
      <label>{{ $t('booking.arrival-date') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="01-01-2021"
        readonly
        v-model="toDate"
      />
      <div class="icon-form"><i class="far fa-calendar-alt"></i></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: {
  },
  data() {
    return {
      fromDate: '00-00-000',
      toDate: '00-00-0000',
      numOfTickets: 0,
      ticketCode: '',
      ticketClass: '',
      ticketType: '',
      ticketSupplier: '',
      ticketName: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      product: 'GET_PRODUCT',
    }),
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      if (!this.product) return;
      this.fromDate = dayjs(this.product.eventTicketReservation.eventFromDate).format('hh:mm DD-MM-YYYY');
      this.toDate = dayjs(this.product.eventTicketReservation.eventToDate).format('hh:mm DD-MM-YYYY');
      this.numOfTickets = this.product.eventTicketReservation.numOfTickets;
      this.ticketCode = this.product.eventTicketReservation.eventCode;
      this.ticketClass = this.product.eventTicketReservation.ticketClassCode;
      this.tickettype = this.product.eventTicketReservation.ticketTypeCode;
      this.ticketSupplier = this.product.eventTicketReservation.eventSupplier;
      this.ticketName = this.product.eventTicketReservation.eventName;
    },
  },
};
</script>

<style scoped>
.room-information {
  font-weight: bold;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}
.price_details_body .form-group {
  position: relative;
  width: 100%;
  margin: 2px 5px;
}

.form-group label {
  font-size: 15px;
  line-height: 0.6rem;
}

.price_details_body .form-group .form-control {
  height: 42px;
}

.price_details_body .form-group.icon .form-control {
  padding-left: 40px;
}

.form-control {
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  direction: 'ltr';
}

.price_details_body .form-group .icon-form {
  content: '';
  position: absolute;
  bottom: 8px;
  left: 14px;
  color: #b0b0b0;
}
.btnDomestic {display:none !important;}
.btn[class*="btnDomestic"] {display:none !important;}
@media (max-width: 767.98px) {
  div.d-flex.justify-content-between {
    display: inline !important;
  }

  .form-group label {
    display: none;
  }

  div.d-flex.justify-content-between > .form-group {
    width: 50%;
    display: inline-flex !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px;
  }

  div.d-flex.justify-content-between > .form-group:last-child {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .form-group label {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .form-group label {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .form-group label {
    font-size: 18px;
  }
}
</style>
